.carousel .thumb img {
    width: 100% !important;
    height: 100% !important;
}

.carousel .slide img {
    
    max-height: 400px;  /* change this to whatever you want */
    width:100%;
}
.carousel .slide {
    background: none;
}
