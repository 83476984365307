
.navbar-div-language-selection{
    background-color: #013501!important;
    color: #ffffff!important;
    /* font-family: sans-serif; */
}
.navbar-div-content{
    background-color: #015001!important;  
    color: #ffffff!important;
    /* font-family: sans-serif!important; */


}

 .navbar-brand {
    color: #ffffff!important;
    font-size: 1rem;
}

.navbar-light .navbar-toggler {
    color: #ffffff!important;
}


 .nav-link  {
    color: #ffffff!important;
    
}
.nav-link-bold  {
    color: #ffffff!important;
    font-weight: bold !important;


}

.menu-active{
    color: #ffffff!important;
    font-weight: bold !important;
    /* border-bottom: 1p solid !important;
    border-bottom-color: red !important; */
}

.navbar-font{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif, 'Catamaran' !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  
.menuactive {
    font-weight: bold !important;
}

.menuInactive {
    font-weight: normal!important;
}

.menuPadding{
    padding: 5px;
}
.menuMargin{
    margin: 5px;
}



/* .navbar-light .navbar-toggler-icon {
    background-image:
url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgb/280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e")
} */

/* .show > .nav-link,
.active > .nav-link,
.nav-link.show, 
 .navbar-nav .nav-link .active {
    font-weight: bold !important;
    border-bottom: 1p solid !important;
    border-bottom-color: red !important;
} */