
dheight{
    height: 100px;
  }
  
  bground{
    background-color: rgba(255,0,0,0.1);
  }
  
  .card {
    width: 15rem;
  }
  .CardFooter{
    border: 0px solid rgb(34, 95, 59) !important;
    margin: 0px;
    background-color: darkgrey !important;
  
  }
  
  
  .footer-div-horizondal-line {
    background-color:#4d8068 !important;
  }
  .footer-div-bgcolor {
    background-color: #16251e!important;
    /* background-color: #16697a!important; */
  }
  
  .footer-div-content-title{
   font-weight: bold;
  margin-bottom: 12px;
  color: #bbc0c4!important;
  /* font-family: sans-serif; */
  }
  
  .footer-div-content-item{
    color: #4d8068 !important;
      padding: 1px 0;
      text-decoration: none;
     font-size: smaller;
      
  }
  
  
  .footer-div-list{
    list-style: none;
    list-style-position: initial;
    list-style-image: initial;
    list-style-type: none;
  }
  
  .footer-font{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif, 'Catamaran' !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .footer-img-align-center{
      align-content: center;
  }
  .nodecoration{
      text-decoration: none;
  }
  
