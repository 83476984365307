
  .sandal-div-horizondal-line {
    background-color:#12463b !important;
  }
  .sandal-div-horizondal-line-light {
    background-color:#268a74 !important;
  }
  .sandal-border-horizondal-line {
    border-color:#268a74 !important;
  }
