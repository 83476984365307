.bcolor_teak{
    border-color: #ecf39e!important;
    border-width: light!important;
}

.bcolor_redsandal{
    border-color: #97a97c!important;
    border-width: light!important;
}

.bcolor_sandalwood{
    border-color: #a0a30b!important;
    border-width: light!important;
}
.bcolor_rosewood{
    border-color: #aad576!important;
    border-width: light!important;
}


