.bordercolor_DailyVerses{
    border-color: #4c9c41!important;
    /* border-width: light!important; */
}

.bgcolor_DailyVerses{
    background-color:#4c9c41!important;
    /* background-color:#523fb9!important; */
}

.fontcolor_DailyVerses{
    color:#18550f!important;
}

.div_whatsapp{
    text-align: right;
    background-color: white!important;
}