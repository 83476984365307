
.div-redsandal-line {
    background-color:#37aa2a !important;
  }
  .div-redsandal-line-light {
    background-color:#58c44c !important;
  }

  .div-bordercolor_redsandal{
    border-color: #58c44c!important;
    /* border-width: light!important; */
}


