
.teak-div-line {
    background-color:#066b33 !important;
    /* 066b33 */
  }

  .teak-div-Description {
    background-color:#239757 !important;
    /* 239757*/
  }


  .bordercolor_Teak{
    border-color: #239757!important;
        /* 239757*/
}

.bgcolor_Teak{
    background-color:#239757!important;
        /* 239757*/
}

.fontcolor_Teak{
    color:#475fa1!important;
}

.div_whatsapp{
    text-align: right;
    background-color: white!important;
}