.div-rosewood {
    background-color:#599951 !important;
    /* 599951*/
  }

  .div-rosewood-light {
    background-color:#77b46f !important;
    /* 77b46f */
  }

  
  .div-border-rosewood-light {
    border-color: #77b46f !important; 
    /* 77b46f */
  }
