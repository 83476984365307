.card {
    width: 21rem !important;
    margin: 10px;
}


.gallery-div-horizondal-line {
    background-color:#4d8068 !important;
  }

.gallery-div-bgcolor {
background-color: #16251e!important;
border:1px solid #16251e!important
}

.border-success1 {
    background-color: #16251e!important;
}

/* 
.nav-link  {
    color: #044b0d!important;
    
}
.nav-link-bold  {
    color: #044b0d!important;
    font-weight: bold !important;

} */